import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Attention, ContentAttention } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "attention"
    }}>{`Attention`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Attention, ContentAttention } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks name={'Attention'} figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=9475%3A205'} storybook={'/?path=/story/content-content--playground'} vueStorybook={'/?path=/story/content-content--playground'} mdxType="ComponentLinks" />
    <p>{`Attention – компонент, выделяющий важную информацию в текстовом потоке.`}</p>
    <p>{`При использовании контентного варианта (`}<inlineCode parentName="p">{`ContentAttention`}</inlineCode>{`), добавляются внешние вертикальные отступы размером в `}<inlineCode parentName="p">{`32px`}</inlineCode></p>
    <Playground __position={1} __code={'<ContentAttention>\n  Здесь располагается какой-то важный текст, на которые есть желание обратить\n  особое внимание, чтобы пользователь не пролистал всю страницу, а остановил\n  свой взгляд и вообще заинтересовался чем-то более выразительным, чем просто\n  сплошной поток однообразного текста.\n</ContentAttention>\n<ContentAttention>\n  Здесь располагается какой-то важный текст, на которые есть желание обратить\n  особое внимание, чтобы пользователь не пролистал всю страницу, а остановил\n  свой взгляд и вообще заинтересовался чем-то более выразительным, чем просто\n  сплошной поток однообразного текста.\n</ContentAttention>'} __scope={{
      props,
      DefaultLayout,
      Attention,
      ContentAttention,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ContentAttention mdxType="ContentAttention">
    Здесь располагается какой-то важный текст, на которые есть желание обратить
    особое внимание, чтобы пользователь не пролистал всю страницу, а остановил
    свой взгляд и вообще заинтересовался чем-то более выразительным, чем просто
    сплошной поток однообразного текста.
  </ContentAttention>
  <ContentAttention mdxType="ContentAttention">
    Здесь располагается какой-то важный текст, на которые есть желание обратить
    особое внимание, чтобы пользователь не пролистал всю страницу, а остановил
    свой взгляд и вообще заинтересовался чем-то более выразительным, чем просто
    сплошной поток однообразного текста.
  </ContentAttention>
    </Playground>
    <h3 {...{
      "id": "пропы"
    }}>{`Пропы`}</h3>
    <Props of={Attention} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      